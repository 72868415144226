import axios from 'axios';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { AdsGETApi, AdsGETApiAuth, AdsPOSTApi, NodePOSTApi, signInImage } from '../../../Constant/api';
import { slugs } from '../../../Constant/slugs';
import { login, logout } from '../../../redux/action';
import store from '../../../redux/store';

import { ExamBlock } from '../ExamBlock';

const slug = {
  "ubt": "UBT",
  "cbt-chemical": "CBT CHEMICAL",
  "cbt-electronics-electricity": "CBT ELECTRONIC ELECTRCITY",
  "cbt-food-related": "CBT FOOD RELATED",
  "cbt-mechinery-molding": "CBT MACHINERY,MOLDING",
  "cbt-metal": "CBT METAL",
  "cbt-pulp-paper-wood": "CBT PULP PAPER WOOD",
  "cbt-rubber": "CBT RUBBER",
  "cbt-textile-sewing": "CBT TEXTILE SEWING"
}
export default function AllDailyExamList() {
  const [AllBatches, setAllBatches] = React.useState([]);
  const [AllBatchesList, setAllBatchesList] = React.useState([]);
  const [error, setError] = React.useState(false);
  const [AllBatchOrders, setAllBatchOrders] = React.useState([]);
  const [MyBatchOrders, setMyBatchOrders] = React.useState([]);
  const [UnverifiedBatchOrders, setUnverifiedBatchOrders] = React.useState([]);
  const [NewBatchOrders, setNewBatchOrders] = React.useState([]);
  const [reload, setReload] = React.useState(false)
  const [pagesLength, setPagesLength] = React.useState("1");
  const [choose, setChoose] = React.useState(1);
  const dispatch = useDispatch()
  const id = store.getState().LoginState.userID

  const fetchExam = (setAllExamList, perPage) => {
    setReload(true)
    NodePOSTApi({ userID: id }, "my-batches")
      .then((res) => {
        console.log(res.data)
        setError(res.data.error)
        setAllBatchOrders(res.data.my_batches)
        setAllBatches(res.data.batch_data.sort((a, b) => a.meta.batch_institute_id - b.meta.batch_institute_id))
        setReload(false)
        const myBatchesArray = res.data.my_batches.filter(a => a.meta.verification_status == "Verified")
        var MyBatchArr = [];
        var UnverifiedBatchArr = [];
        var NewBatchArray = [];
        for (let i = 0; i < res.data.batch_data.length; i++) {
          const batchDataJson = res.data.batch_data[i]
          const BatchFilter = res.data.my_batches.filter(b => b.meta.batch_id_order == batchDataJson.id)
          if (BatchFilter.length !== 0) {
            if (BatchFilter[0].meta.verification_status == "Verified") {
              MyBatchArr.push(batchDataJson)
              setMyBatchOrders(MyBatchArr)
            }
            else {
              UnverifiedBatchArr.push(batchDataJson)
              setUnverifiedBatchOrders(UnverifiedBatchArr)
            }
          }
          else {
            NewBatchArray.push(batchDataJson)
            setNewBatchOrders(NewBatchArray)
          }
        }
      })
      .catch(err => {
        console.log(err)
        console.log(err.response.data)
      })
  }
  const location = useLocation()
  const LoginUser = useSelector((state) => state.LoginState);
  useEffect(() => {
    fetchExam()
  }, [])

  const MyBatchDetails = ({ item, index }) => {
    const AllExamList = JSON.parse(item.meta.batch_exam_list)
    const [ExamList, setExamList] = React.useState(AllExamList.slice(0, 6))
    return (
      <div id={`${item.id}`}>



        <h3>Batch: {item.meta.batch_name}</h3>
        <h4>Exam List</h4>
        <div className="row" >
          {
            ExamList.map((item2, index2) => (
              <div className="col-lg-4 col-md-6 col-12 center">
                <div key={index2} style={{
                  padding: "5px", margin: "5px 0px", border: "1.5px solid #bbb",
                  borderRadius: "5px",
                }}>
                  {item2.featured_image !== "" ?
                    <img className="col-12" src={item2.featured_image}
                      style={{ objectFit: 'contain', height: "100px", borderRadius: "3px" }} />
                    :
                    <img className="col-12"
                      src={"https://api.epsexam.com/wp-content/uploads/2023/02/eps-logo.jpeg"}
                      style={{ objectFit: 'contain', height: "100px", borderRadius: "3px" }} />
                  }
                  <br />
                  <div>
                    {item2.name}&nbsp;
                  </div>

                  <div style={{ width: "100%", display: 'flex', justifyContent: 'center' }}>
                    <Link className="sign-in-button-1 col-8 Link"
                      to={`${slugs.start_daily_exam}/${item2.slug}`}
                    >
                      <div style={{ color: "#fff" }}>
                        Start Exam
                      </div>
                    </Link>
                  </div>
                </div >
              </div>
            ))
          }
        </div>
        <div style={{ textAlign: "right", padding: "7px", cursor: "pointer" }}
          onClick={() => {
            if (ExamList.length !== AllExamList.length)
              setExamList(AllExamList)
            else
              setExamList(AllExamList.slice(0, 6))
          }}
        >
          {ExamList.length !== AllExamList.length ?
            "Show All Exams..." :
            "Hide Exams"
          }
        </div>
      </div>
    )
  }

  const BatchesBlock = ({ item, index }) => {
    const MyBatchFound = AllBatchOrders.filter(a => a.meta.batch_id_order == item.id)
    return (
      <div>
        <div style={{
          padding: "5px 0", margin: "5px 0px", border: "1.5px solid #bbb",
          borderRadius: "5px",
        }} className="row">
          <div style={{ width: "100%", }}>
            <div style={{ textAlign: "left", fontSize: "12px" }}>
              {item.meta.batch_image !== "" ?
                <img className="col-12" src={item.meta.batch_image}
                  style={{ objectFit: 'contain', height: "150px", borderRadius: "3px" }} />
                :
                <img className="col-12"
                  src={signInImage}
                  style={{ objectFit: 'contain', height: "150px", borderRadius: "3px" }} />
              }
              <div style={{ textAlign: "center" }}>
                {item.meta.batch_name}<br />
                Rs.{item.meta.batch_price}
              </div>
              <div>
                {MyBatchFound.length == 0 ?
                  <span>
                    <div className="row">
                      <Link to={`${slugs.batch_payment}/${item.slug}`}
                        style={{ width: "100%", display: 'flex', justifyContent: 'center' }}>
                        <div className="sign-in-button-12" style={{ width: "50%", }}>
                          Buy Batch
                        </div>
                      </Link>
                    </div>
                  </span>
                  :
                  <>
                    {MyBatchFound[0].meta.verification_status == "Verified" ?
                      <span>
                        <div className="row">
                          <Link to={`${slugs.batch_exam}/${item.slug}`}
                            style={{ width: "100%", display: 'flex', justifyContent: 'center' }}>
                            <div className="sign-in-button-12" style={{ width: "50%" }}>
                              View Batch
                            </div>
                          </Link>
                        </div>
                      </span>
                      :
                      <span>
                        <div className="row">
                          <Link to={`${slugs.batch_payment}/${item.slug}`}
                            style={{ width: "100%", display: 'flex', justifyContent: 'center' }}>
                            <div className="sign-in-button-12" style={{ width: "50%" }}>
                              Pay Now
                            </div>
                          </Link>
                        </div>
                      </span>
                    }
                  </>
                }
              </div>
            </div>
          </div>
        </div>
      </div >
    )
  }

  return (
    <div>


      <div className="row">
        <div className="col-1"></div>
        <div className="col-10 form-view mt-3">
          <div className="row" style={{ margin: "10px" }}>
            <h2>All Exam</h2>
          </div>
          <div style={{ margin: "10px", cursor: "pointer" }}>
            <span onClick={() => { setChoose(1) }}
            > All Packages ({AllBatches.length}) | </span>
            <span onClick={() => { setChoose(2) }}
            >My Packages ({MyBatchOrders.length}) | </span>
            <span onClick={() => { setChoose(3) }}
            >New Packages ({NewBatchOrders.length}) | </span>
            <span onClick={() => { setChoose(4) }}
            >Unverified Package ({UnverifiedBatchOrders.length}) | </span>
          </div>

          {choose == 1 &&
            <div className="row" style={{ margin: "10px" }}>
              {AllBatches.map((item, index) => (
                <div key={index} className="col-lg-4 col-md-6 col-12 center" style={{ padding: "0 5px" }}>
                  <BatchesBlock item={item} index={index} />
                </div>
              ))}
            </div>
          }
          {choose == 4 &&
            <div className="row" style={{ margin: "10px" }}>
              {UnverifiedBatchOrders.map((item, index) => (
                <div key={index} className="col-lg-4 col-md-6 col-12 center" style={{ padding: "0 5px" }}>
                  <BatchesBlock item={item} index={index} />
                </div>
              ))}
            </div>
          }

          {choose == 2 &&
            <div className="row" style={{ margin: "10px" }}>
              {MyBatchOrders.map((item, index) => (
                <div key={index} className="col-lg-4 col-md-6 col-12 center" style={{ padding: "0 5px" }}>
                  <BatchesBlock item={item} index={index} />
                </div>
              ))}
            </div>
          }
          {choose == 3 &&
            <div className="row" style={{ margin: "10px" }}>
              {NewBatchOrders.map((item, index) => (
                <div key={index} className="col-lg-4 col-md-6 col-12 center" style={{ padding: "0 5px" }}>
                  <BatchesBlock item={item} index={index} />
                </div>
              ))}
            </div>
          }
        </div>
      </div>
      {reload &&
        <div className="modal" style={{ padding: "50vw" }}>
          <div className="dot-pulse"></div>
        </div>
      }
    </div>
  )
}