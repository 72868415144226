import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { AdsGETApiAuth, NodePOSTApi } from '../../../Constant/api';
import { slugs } from '../../../Constant/slugs';
import store from '../../../redux/store';
import NotFound from '../../NotFound';
import FirstScreenExam from '../ExamDetails';
import { FullScreen, useFullScreenHandle } from "react-full-screen";

export default function StartDailyExam(props) {
  const dispatch = useDispatch()
  const location = useLocation()
  const [ExamData, setAllExamList] = React.useState({});
  const [ExamFetch, setExamFetch] = React.useState(false);
  const [NotFoundData, setNotFound] = React.useState(false)
  const [reload, setReload] = React.useState(false)
  const [choose, setChoose] = React.useState(0)
  const [AnswerArray, setAnswerArray] = React.useState([]);
  const [ResultData, setResultData] = React.useState({});

  const fetchExam = (slug) => {
    setExamFetch(false)
    setReload(true)
    setNotFound(false)
    const id = store.getState().LoginState.userID
    // AdsGETApiAuth({ slug: slug }, slugs.db_slug_daily_exam)
    //   .then((res) => {
    //     if (res.data.length == 1) {
    //       AdsGETApiAuth({
    //         "filter[meta_query][1][key]": "exam_id_results",
    //         "filter[meta_query][1][value][0]": res.data[0].id,
    //         "filter[meta_query][1][compare]": "=",
    //         "filter[meta_query][relation]": "AND",
    //         "filter[meta_query][2][key]": "student_id_results",
    //         "filter[meta_query][2][value][0]": id,
    //         "filter[meta_query][2][compare]": "=",
    //       }, slugs.db_slug_daily_results)
    //         .then((response) => {
    //           console.log(id)
    //           if (response.data.length !== 0) {
    //             setChoose(2)
    //             setAnswerArray(JSON.parse(response.data[0].meta.exam_answers_array_results))
    //             setResultData(response.data[0])
    //           }
    //           if (res.data[0].meta.exam_question_block_text !== undefined)
    //             setAllExamList(res.data[0])
    //           else {
    //             setNotFound(true)
    //           }

    //           setExamFetch(true)
    //         })
    //         .finally(() => {
    //           setReload(false)
    //         })
    //     }
    //     else {
    //       setNotFound(true)
    //       setExamFetch(true)
    //       setReload(false)
    //     }
    //   })
    //   .catch(err => {
    //     console.log(err)
    //     console.log(err.response.data)
    //   })

    NodePOSTApi({ userID: id, examSlug: slug }, "start-batch-exam-details")
      // axios({
      //   method: "POST",
      //   url: `http://localhost:4000/start-batch-exam-details`,
      //   data: {
      //     userID: id,
      //     examSlug: slug
      //   }
      // })
      .then(res => {
        // console.log(res.data.error)
        if (res.data.error == true) {
          setNotFound(true)
          // console.log(41)
          setExamFetch(true)
        }
        else {
          // if (res.data.result_data.length !== 0) {
          //   setChoose(2)
          //   // console.log(res.data.result_data[0])
          //   setAnswerArray(JSON.parse(res.data.result_data[0].meta.exam_answers_array_results))
          //   setResultData(res.data.result_data[0])
          // }
          setAllExamList(res.data.exam_data[0])
          setExamFetch(true)
        }
      })
      .finally(() => {
        setReload(false)
      })
      .catch(err => {
        console.log(err)
        console.log(err.response.data)
      })

  }
  const [finishStatus, setfinishStatus] = React.useState(false);
  const handle = useFullScreenHandle();

  useEffect(() => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    }
    else
      handle.enter()
    fetchExam(location.pathname.slice(slugs.start_daily_exam.length + 1))
  }, [])

  return (
    <div
      style={{ backgroundColor: "#fff" }}
    >
      <FullScreen handle={handle}>

        <div style={{ backgroundColor: "#eee", minHeight: "100vh" }}>
          <div>
            <div className="row">
              <div className="form-view m-3">
                {ExamFetch &&
                  <>
                    {NotFoundData ?
                      <NotFound /> :
                      <>
                        <FirstScreenExam
                          ExamData={ExamData}
                          setReload={setReload}
                          choose={choose}
                          setChoose={setChoose}
                          AnswerArray={AnswerArray}
                          setAnswerArray={setAnswerArray}
                          ResultData={ResultData} setResultData={setResultData}
                        />
                      </>
                    }
                  </>
                }
              </div>
            </div>
          </div>
          {reload &&
            <div className="modal" style={{ padding: "50vw" }}>
              <div className="dot-pulse"></div>
            </div>
          }
        </div>
      </FullScreen>
    </div>
  )
}